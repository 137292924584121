export const a = {
  "--accent-a": "crimson",
  "--accent-b": "lawngreen",
  "--accent-c": "dodgerblue",
  "--background-color": "black",
  "--color-a": "white",
  "--color-b": "yellow",
};

export const b = {
  "--accent-a": "gold",
  "--accent-b": "darkorange",
  "--accent-c": "maroon",
  "--background-color": "oldlace",
  "--color-a": "black",
  "--color-b": "black",
};

export const c = {
  "--accent-a": "crimson",
  "--accent-b": "crimson",
  "--accent-c": "dimgray",
  "--background-color": "whitesmoke",
  "--color-a": "black",
  "--color-b": "black",
};

export const d = {
  "--accent-a": "deeppink",
  "--accent-b": "red",
  "--accent-c": "crimson",
  "--background-color": "indigo",
  "--color-a": "white",
  "--color-b": "deeppink",
};

export const e = {
  "--accent-a": "deeppink",
  "--accent-b": "mediumvioletred",
  "--accent-c": "blue",
  "--background-color": "peachpuff",
  "--color-a": "navy",
  "--color-b": "deeppink",
};

export const f = {
  "--accent-a": "black",
  "--accent-b": "dimgray",
  "--accent-c": "gainsboro",
  "--background-color": "white",
  "--color-a": "black",
  "--color-b": "black",
};

export const g = {
  "--accent-a": "dimgray",
  "--accent-b": "Teal",
  "--accent-c": "Teal",
  "--background-color": "darkslategray",
  "--color-a": "white",
  "--color-b": "Teal",
};

export const h = {
  "--accent-a": "orange",
  "--accent-b": "dimgray",
  "--accent-c": "dimgray",
  "--background-color": "black",
  "--color-a": "silver",
  "--color-b": "orange",
};
