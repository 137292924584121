// Components
export const SPACE = "space";
export const TEXT = "text";

// Directions
export const COLUMN = "column";
export const ROW = "row";

// Joining
export const JOIN = "-";

// Sides
export const TOP = "top";
export const RIGHT = "right";
export const BOTTOM = "bottom";
export const LEFT = "left";

// Other
export const AUTO = "auto";
export const NONE = "none";
export const DIRECTION = "direction";
export const MARGIN = "margin";
export const PADDING = "padding";
export const SIDES = [TOP, RIGHT, BOTTOM, LEFT];
export const WIDTH = "width";
export const NOWRAP = "nowrap";
export const WRAP = "wrap";

// // Components
// export const BLOCK = "b";
// export const TEXT = "t";

// // Directions
// export const COLUMN = "c";
// export const ROW = "r";

// // Joining
// export const JOIN = "";

// // Sides
// export const TOP = "t";
// export const RIGHT = "r";
// export const BOTTOM = "b";
// export const LEFT = "l";

// // Other
// export const AUTO = "a";
// export const NONE = "n";
// export const DIRECTION = "d";
// export const MARGIN = "m";
// export const PADDING = "p";
// export const SIDES = [TOP, RIGHT, BOTTOM, LEFT];
// export const WIDTH = "w";
// export const NOWRAP = "n";
// export const WRAP = "w";
