function Circle({ size = 300 }: { size?: number }) {
  return (
    <span
      style={{
        aspectRatio: "1 / 1",
        backgroundColor: "cyan",
        backgroundImage:
          "linear-gradient(45deg, var(--accent-a),var(--accent-b),var(--accent-c))",
        borderRadius: "50%",
        display: "block",
        margin: "auto",
        maxWidth: "100%",
        width: size,
        zIndex: 1,
      }}
    />
  );
}

export default Circle;
