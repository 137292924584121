import React from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import { default as styles } from "react-syntax-highlighter/dist/esm/styles/prism/a11y-dark";

SyntaxHighlighter.registerLanguage("jsx", jsx);

styles['pre[class*="language-"]'].margin = "0";

type Props = {
  children: string;
  language?: string;
};

const Code = ({ children, language = "jsx" }: Props) => {
  return (
    <div style={{ fontSize: "inherit" }}>
      <SyntaxHighlighter language={language} style={styles}>
        {children.trim()}
      </SyntaxHighlighter>
    </div>
  );
};

export default Code;
