import { CSSProperties, ElementType, ReactNode } from "react";
import { AUTO, SPACE, COLUMN, NONE, NOWRAP, ROW, WRAP } from "../constants";
import size from "../utilities/size";

type Properties = {
  direction?: typeof COLUMN | typeof ROW;
  margin?: number | number[];
  padding?: number | number[];
  width?: number | typeof AUTO | typeof NONE;
  wrap?: typeof NOWRAP | typeof WRAP;
};

type Props = {
  children?: ReactNode;
  element?: ElementType;
  style?: CSSProperties;
  x?: Properties;
  s?: Properties;
  m?: Properties;
  l?: Properties;
};

const properties = {
  margin: [],
  padding: [],
};

export function Space({
  children,
  element: Element = "div",
  style,
  x = properties,
  s = properties,
  m = properties,
  l = properties,
}: Props) {
  const classList = [
    SPACE,
    ...[...size("x", x), ...size("s", s), ...size("m", m), ...size("l", l)],
  ];
  return (
    <Element className={classList.join(" ")} style={style}>
      {children}
    </Element>
  );
}
