import { Space, Text } from "./hyperspacing";

import "./App.scss";

import Components from "./partials/Components";
import Features from "./partials/Features";
import Header from "./partials/Header";
import Installation from "./partials/Installation";
import Responsive from "./partials/Responsive";
import Typography from "./partials/Typography";
import Themes from "./partials/Themes";

function App() {
  const version = process.env.REACT_APP_VERSION;
  return (
    <Space
      style={{ margin: "0 auto", maxWidth: 1400 }}
      x={{ padding: 3 }}
      m={{ padding: 4 }}
    >
      <Header />

      <Space element="main">
        <Features />
        <Installation />
        <Responsive />
        <Typography />
        <Components />
      </Space>

      <Themes />

      <Space element="footer" x={{ padding: [4, 3, 5] }}>
        <Text element="p">
          {version && version} Components from hyperspace.
        </Text>
      </Space>
    </Space>
  );
}

export default App;
