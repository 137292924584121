import { AUTO, COLUMN, JOIN, MARGIN, PADDING, ROW, SIDES } from "../constants";
import shorthand from "./shorthand";

type Properties = {
  direction?: typeof COLUMN | typeof ROW;
  margin?: number | number[];
  padding?: number | number[];
  basis?: number | typeof AUTO;
};

export default function size(name: string, properties: Properties) {
  const { margin, padding, ...rest } = properties;
  let array: string[] = [];
  if (rest)
    array = array.concat(
      Object.entries(rest).map(([key, value]) => {
        const property = JOIN.length === 1 ? key : key[0];
        return `${name}${JOIN}${property}${JOIN}${value}`;
      })
    );
  if (margin)
    array = array.concat(shorthand(margin, MARGIN, SIDES, name, JOIN));
  if (padding)
    array = array.concat(shorthand(padding, PADDING, SIDES, name, JOIN));
  return array;
}
