import React from "react";
import { Space, Text, AUTO, NONE, ROW, WRAP } from "../hyperspacing";

function TextExample({ size = 1 }: { size: number }) {
  return (
    <Space
      x={{ direction: ROW, margin: [0, 0, 3], wrap: WRAP }}
      style={{
        borderColor: "var(--accent-a)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Space x={{ padding: 3, width: AUTO }} m={{ width: 2 }}>
        <Text>Text</Text>
      </Space>
      <Space x={{ padding: 3, width: NONE }} m={{ width: 2 }}>
        <Text>{size}</Text>
      </Space>
      <Space x={{ padding: 3, width: 12 }} m={{ width: 8 }}>
        <Text x={{ size }}>Hyperspace</Text>
      </Space>
    </Space>
  );
}

function Typography() {
  return (
    <Space
      x={{ margin: [0, 0, 3], padding: 3 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Text
        element="h2"
        style={{ fontWeight: 700 }}
        x={{ size: 2 }}
        m={{ size: 3 }}
      >
        TEXT SIZE
      </Text>
      <Text>
        Text size can be controlled at different view widths with X, S, M and L
        properties, the latter taking priority over that preceeding it. If an
        element is the same size on all view widths then size can be define
        using X with no proceeding declarations. All properties are should
        defined using a lowercase character.
      </Text>
      <TextExample size={1} />
      <TextExample size={2} />
      <TextExample size={3} />
      <TextExample size={4} />
    </Space>
  );
}

export default Typography;
