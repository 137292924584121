import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export default function Clickable({ children, ...rest }: Props) {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "block",
          position: "relative",
          zIndex: 1,
        }}
      >
        {children}
      </div>
      <button
        aria-label="Clickable"
        title="Clickable"
        style={{
          appearance: "none",
          background: "transparent",
          border: 0,
          display: "block",
          height: "100%",
          left: 0,
          padding: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 2,
        }}
        {...rest}
      />
    </div>
  );
}
