import { Space, Text } from "../hyperspacing";
import Code from "../components/Code";

function Components() {
  return (
    <Space
      x={{ margin: [0, 0, 3], padding: 2 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Space x={{ padding: 3 }}>
        <Text
          element="h3"
          style={{ fontWeight: 700 }}
          x={{ size: 2 }}
          m={{ size: 3 }}
        >
          COMPONENTS
        </Text>
        <Text element="p">
          Hyperspacing duel component system allows superier control of layouts
          at a number of different screen sizes.
        </Text>
      </Space>
      <Space x={{ padding: 3 }}>
        <Text
          element="h4"
          style={{ fontWeight: 700 }}
          x={{ size: 1 }}
          m={{ size: 2 }}
        >
          SPACE
        </Text>
        <Text>
          Space is used to contain and define an area as well as the margin and
          padding around that space.
        </Text>
        <Code>
          {`
<Space x={{ padding: 3 }} m={{ width: 6 }} l={{ width: 3 }}>
  ...
</Space>
            `}
        </Code>
      </Space>
      <Space x={{ padding: 3 }}>
        <Text
          element="h4"
          style={{ fontWeight: 700 }}
          x={{ size: 1 }}
          m={{ size: 2 }}
        >
          TEXT
        </Text>
        <Text>
          Text is used to control text sizes at different screen sizes as well
          as the margin and padding of text.
        </Text>
        <Code>
          {`
<Text
  element="h4"
  style={{ fontWeight: 700 }}
  x={{ margin: 1, size: 1 }}
  m={{ margin: [1, 2, 3, 4], size: 2 }}
>
  TEXT
</Text>
            `}
        </Code>
      </Space>
    </Space>
  );
}

export default Components;
