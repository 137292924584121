import React from "react";
import { Space, Text } from "../hyperspacing";
// import Disc from "../components/Disc";

function Header() {
  return (
    <Space
      element="header"
      x={{ margin: [0, 0, 3], padding: 3 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Text
        element="h1"
        style={{ fontWeight: 700, position: "relative", zIndex: 1 }}
        x={{ size: 3 }}
        m={{ size: 4 }}
      >
        {/* <Disc /> */}
        HYPERSPACING
      </Text>
      <Text
        element="h2"
        style={{ color: "var(--color-b)" }}
        x={{ size: 1 }}
        m={{ size: 2 }}
      >
        WEB INTERFACE REACT COMPONENT LIBRARY
      </Text>
      <Space
        style={{
          backgroundColor: "var(--accent-a)",
          transition: "background-color 200ms ease-in-out",
        }}
        x={{ padding: [2] }}
      />
      <Space
        style={{
          backgroundColor: "var(--accent-b)",
          transition: "background-color 200ms ease-in-out",
        }}
        x={{ padding: [2] }}
      />
      <Space
        style={{
          backgroundColor: "var(--accent-c)",
          transition: "background-color 200ms ease-in-out",
        }}
        x={{ padding: [2] }}
      />
    </Space>
  );
}

export default Header;
