import { Space, Text, COLUMN, ROW, NOWRAP, WRAP } from "../hyperspacing";

const borderStyles = {
  backgroundColor: "var(--background-color)",
  borderColor: "var(--accent-a)",
  borderStyle: "solid",
  borderWidth: 1,
  transition:
    "background-color 200ms ease-in-out, border-color 200ms ease-in-out",
};

function Responsive() {
  return (
    <Space
      x={{ margin: [0, 0, 3], padding: 3 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Space x={{ padding: 3 }}>
        <Text
          element="h3"
          style={{ fontWeight: 700 }}
          x={{ size: 2 }}
          m={{ size: 3 }}
        >
          RESPONSIVE
        </Text>
        <Text element="p">
          Layout can be controlled at different view widths with X, S, M and L
          properties, the latter taking priority over those preceeding it. If an
          element is not responsive then no proceeding declarations are
          required. All properties are should defined using a lowercase
          character.
        </Text>
      </Space>
      <Space
        s={{ direction: COLUMN }}
        m={{ direction: ROW, wrap: WRAP }}
        l={{ direction: ROW, wrap: NOWRAP }}
      >
        <Space x={{ padding: 3 }} m={{ width: 6 }} l={{ width: 3 }}>
          <Space
            style={{
              ...borderStyles,
              height: 240,
            }}
            x={{ padding: 3 }}
          >
            <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
              X 0
            </Text>
            <Text>
              X refers to sizes greater than 0 and can be used to define
              properties for an element. These element properties can be
              superseded at larger sizes by proceeding declarations.
            </Text>
          </Space>
        </Space>
        <Space x={{ padding: 3 }} m={{ width: 6 }} l={{ width: 3 }}>
          <Space
            style={{
              ...borderStyles,
              height: 240,
            }}
            x={{ padding: 3 }}
          >
            <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
              S 600
            </Text>
            <Text>
              S refers to a small window size. Any S declarations supersede X
              declarations and any X declarations that are not declared continue
              to persist at greater sizes.
            </Text>
          </Space>
        </Space>
        <Space x={{ padding: 3 }} m={{ width: 6 }} l={{ width: 3 }}>
          <Space
            style={{
              ...borderStyles,
              height: 240,
            }}
            x={{ padding: 3 }}
          >
            <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
              M 900
            </Text>
            <Text>
              M refers to a medium window size. Any M declarations supersede X
              and S declarations and any X, and S declarations that are not
              declared continue to persist at greater sizes.
            </Text>
          </Space>
        </Space>
        <Space x={{ padding: 3 }} m={{ width: 6 }} l={{ width: 3 }}>
          <Space
            style={{
              ...borderStyles,
              height: 240,
            }}
            x={{ padding: 3 }}
          >
            <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
              L 1,200
            </Text>
            <Text>
              L refers to a large window size. Any L declarations supersede X, S
              and M declarations and any X, S and M declarations that are not
              declared continue to persist at greater sizes.
            </Text>
          </Space>
        </Space>
      </Space>
    </Space>
  );
}

export default Responsive;
