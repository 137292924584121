export default function shorthand(
  input: number | number[],
  property: string,
  sides: string[],
  basis: string,
  join: string
) {
  const classList: string[] = [];
  if (Number.isInteger(input)) {
    classList.push(`${basis}${join}${property}${join}${input}`);
  }
  const array = input instanceof Array ? input : [];
  const { length } = array;
  switch (length) {
    case 1:
      classList.push(`${basis}${join}${property}${join}${array[0]}`);
      break;
    case 2:
      sides.map((side, index) =>
        classList.push(
          `${basis}${join}${property}${join}${side}${join}${
            array[index % 2 === 0 ? 0 : 1]
          }`
        )
      );
      break;
    case 3:
      sides.map((side, index) =>
        classList.push(
          `${basis}${join}${property}${join}${side}${join}${
            array[index === 3 ? 1 : index]
          }`
        )
      );
      break;
    case 4:
      sides.map((side, index) =>
        classList.push(
          `${basis}${join}${property}${join}${side}${join}${array[index]}`
        )
      );
      break;
    default:
      break;
  }
  return classList;
}
