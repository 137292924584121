import { useEffect } from "react";
import { Space, Text, COLUMN, ROW, WRAP } from "../hyperspacing";
import Clickable from "../components/Clickable";

import * as themes from "../themes";

function setDocumentProperties(object: object) {
  Object.entries(object).map(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
    return null;
  });
}

const borderStyles = {
  backgroundColor: "var(--background-color)",
  borderColor: "var(--accent-a)",
  borderStyle: "solid",
  borderWidth: 1,
  transition:
    "background-color 200ms ease-in-out, border-color 200ms ease-in-out",
};

function Themes() {
  useEffect(() => {
    setDocumentProperties(themes.a);
  }, []);
  function handleTheme(theme: object) {
    setDocumentProperties(theme);
  }
  return (
    <Space element="aside">
      <Space x={{ padding: 3 }}>
        <Text
          element="h3"
          style={{ fontWeight: 700 }}
          x={{ size: 2 }}
          m={{ size: 3 }}
        >
          THEMING
        </Text>
        <Text element="p">
          Click or tap on the tiles below to view examples of theming.
        </Text>
      </Space>
      <Space x={{ direction: COLUMN }} s={{ direction: ROW, wrap: WRAP }}>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.a)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                T120 HQ
              </Text>
              <Text>
                Dark theme with yellow, crimson, lawn-green and dodger-blue
                accents.
              </Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.b)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                H-320
              </Text>
              <Text>Light theme with gold, dark-orange and maroon.</Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.c)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                ES T120
              </Text>
              <Text>
                Light duotone theme using crimson, gainsboro and black.
              </Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.d)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                A
              </Text>
              <Text>
                Vibrant theme composing deep-pink, red, maroon and indigo.
              </Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.e)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                L-750 UHG
              </Text>
              <Text>
                Light theme comprised of deep-pink, medium-violet-red, blue,
                peachpuff and navy.
              </Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.f)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                MONO
              </Text>
              <Text>A light monochromatic theme.</Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.g)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                G
              </Text>
              <Text>A light monochromatic theme.</Text>
            </Space>
          </Clickable>
        </Space>
        <Space
          x={{ padding: 3 }}
          s={{ width: 6 }}
          m={{ width: 4 }}
          l={{ width: 3 }}
        >
          <Clickable onClick={() => handleTheme(themes.h)}>
            <Space
              style={{
                ...borderStyles,
                height: 240,
              }}
              x={{ padding: 3 }}
            >
              <Text element="h3" style={{ fontWeight: "bold" }} x={{ size: 2 }}>
                H
              </Text>
              <Text>A light monochromatic theme.</Text>
            </Space>
          </Clickable>
        </Space>
      </Space>
    </Space>
  );
}

export default Themes;
