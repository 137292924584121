import { CSSProperties, ElementType, ReactNode } from "react";
import { TEXT } from "../constants";
import size from "../utilities/size";

type Properties = {
  size?: number;
  margin?: number | number[];
  padding?: number | number[];
};

type Props = {
  children: ReactNode;
  element?: ElementType;
  style?: CSSProperties;
  x?: Properties;
  s?: Properties;
  m?: Properties;
  l?: Properties;
};

const properties = {
  margin: [],
  padding: [],
};

export function Text({
  children,
  element: Element = "p",
  style,
  x = properties,
  s = properties,
  m = properties,
  l = properties,
}: Props) {
  const classList = [
    TEXT,
    ...[...size("x", x), ...size("s", s), ...size("m", m), ...size("l", l)],
  ];
  return (
    <Element className={classList.join(" ")} style={style}>
      {children}
    </Element>
  );
}
