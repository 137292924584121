import { Space, Text } from "../hyperspacing";
import Code from "../components/Code";

function Installation() {
  return (
    <Space
      x={{ margin: [0, 0, 3], padding: 3 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Text
        element="h2"
        style={{ fontWeight: 700 }}
        x={{ size: 2 }}
        m={{ size: 3 }}
      >
        INSTALLATION
      </Text>
      <Text>Run the following to add hyperspacing to your project.</Text>
      <Code language="bash">
        {`
npm install hyperspacing
            `}
      </Code>
    </Space>
  );
}

export default Installation;
