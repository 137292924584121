import { Space, Text, AUTO, NONE, ROW } from "../hyperspacing";
import Circle from "../components/Circle";

function Feature({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Space
      style={{
        borderColor: "var(--accent-a)",
        borderStyle: "solid",
        borderWidth: 1,
        height: "100%",
      }}
      x={{ padding: 3 }}
      m={{ width: 6 }}
    >
      <Space x={{ direction: ROW }}>
        <Space x={{ width: NONE }}>
          <Circle size={80} />
        </Space>
        <Space x={{ padding: [0, 0, 0, 3], width: AUTO }}>
          <Text
            element={"h3"}
            style={{ fontWeight: 700 }}
            x={{ size: 1 }}
            m={{ size: 2 }}
          >
            {title}
          </Text>
          <Text>{description}</Text>
        </Space>
      </Space>
    </Space>
  );
}

function Typography() {
  return (
    <Space
      x={{ margin: [0, 0, 3], padding: 2 }}
      style={{
        borderColor: "var(--color)",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <Space x={{ padding: 2 }}>
        <Text
          element="h2"
          style={{ fontWeight: 700 }}
          x={{ size: 2 }}
          m={{ size: 3 }}
        >
          FEATURES
        </Text>
      </Space>
      <Space m={{ direction: ROW }}>
        <Space x={{ padding: 2 }} m={{ width: 6 }}>
          <Feature
            title="DUEL COMPONENT SYSTEM"
            description="Some compelling reason why this is a good thing."
          />
        </Space>
        <Space x={{ padding: 2 }} m={{ width: 6 }}>
          <Feature
            title="COMPELLING FEATURE 2"
            description="Some other compelling argument as to why this exercise was useful."
          />
        </Space>
      </Space>
    </Space>
  );
}

export default Typography;
